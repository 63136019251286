import { Box, Grid, Heading, Text } from 'theme-ui'

import { BaseLayout } from '../layout/base'
import { Footer } from '../components/Footer'
import { Section } from '../components/Section'
import { Header } from '../components/Header'
import { Container } from '../components/Container'
import { Link } from '../components/Link'
import { base } from '../theme'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

interface MarkdownPageProps {
  data: {
    markdownRemark: {
      frontmatter: any
      html: any
    }
  }
}

const MarkdownPage = ({ data }: MarkdownPageProps) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <BaseLayout>
      <Helmet>
        <title>{frontmatter.title} | Privy</title>
      </Helmet>

      <main>
        <Section
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Container>
            <Grid
              sx={{
                py: [4, null, null, 16],
                gap: 8,
                placeItems: ['center', null, null, 'start'],
              }}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: html }}
                sx={{ '& h1, & h2, & h3, & h4, & h5, & h6': { fontWeight: 'light' }, '& a': { color: 'inherit' } }}
              />
            </Grid>
          </Container>
        </Section>
      </main>

      <Footer />
    </BaseLayout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        # date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

export default MarkdownPage
